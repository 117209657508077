<template>
  <b-container fluid>
    <template v-if="p_port">
      <b-row>
        <b-col>
          <h1 class="my-4">
            {{ p_port.mdl_fq_name }} {{ p_port.name }}
            <small class="text-muted">
              {{ $t('views.nd.netdoc.p_port') }}
              <template v-if="parent_module">
                <NETVSIcon icon="active_module" v-if="parent_module.is_active"/>
                <NETVSIcon icon="dd_module" v-else-if="module_type && module_type.class === 'DD'"/>
              </template>
            </small>
          </h1>
        </b-col>
        <b-col>
          <div class="float-right" style="margin-top: 1.5rem">
            <b-button-group>
              <b-button variant="outline-secondary" @click="showEVLog()">
                {{ $t('system.event_log') }}
                <NETVSIcon icon="evlog"></NETVSIcon>
              </b-button>
              <b-button @click="show_map_for_p_port(p_port)" v-if="$store.state.expert"
                        :id="'button-show-path-' +  p_port_gpk"
                        variant="outline-primary">
                {{ $t('views.nd.bcd.connection_path') }}
                <NETVSIcon icon="graph"/>
              </b-button>
              <template>
                <template v-if="!parent_module">
                  <Loading :data="null"/>
                </template>
                <template v-else>
                  <b-button :to="'/netdoc/modules/' + remote_module.gpk + '/p_port/' + remote_p_port.gpk"
                            v-if="remote_p_port" variant="outline-primary">
                    {{ $t('views.nd.netdoc.remote_p_port') }}
                    <NETVSIcon icon="target_module"/>
                  </b-button>
                  <b-button :to="'/netdoc/modules/' + parent_module.gpk" variant="outline-primary">
                    {{ $t('views.nd.netdoc.module') }}
                    <NETVSIcon icon="target_module"/>
                  </b-button>
                </template>
              </template>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-n4">
        <b-col>
          <ComponentLocation :bldg_name="p_port.mdl_bldg" :room_name="p_port.mdl_room"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" cols-lg="12">
          <b-card class="shadow mb-4" no-body>
            <b-card-header v-b-toggle:vlan-collapse>
              <h5 class="d-inline">{{ $tc('system.vlan', assigned_bcd_vlan_list.length) }}</h5>
              <NETVSIcon class="collapse-icon" icon="collapse"></NETVSIcon>
            </b-card-header>
            <b-card-body body-class="p-0">
              <b-collapse id="vlan-collapse" :visible="true">
                <PaginatorTable :hide-filter="assigned_bcd_vlan_list.length < 10" :hideTopPagination="true"
                                :items="assigned_bcd_vlan_list" :fields="vlan_list_fields">
                  <template v-slot:cell(bcd_name)="data">
                    <BCDId :bcd_vlans="vlans_of_bcd[data.item.bcd_name]" :bcd="bcds[data.item.bcd_name]"
                           :bcd_vni="data.item.vxlan" class="mr-1"/>
                    <RouterLink :to="'/netdoc/bcds/' + data.item.bcd_name">{{ data.item.bcd_name }}</RouterLink>
                  </template>
                  <template v-slot:cell(tagged)="data">
                    <CheckMark :state="data.item.tagged">
                      <template v-slot:true_text>
                        ({{ $t('views.nd.bcd.tag') }}: {{ data.item.vlan_id }})
                      </template>
                    </CheckMark>
                  </template>
                </PaginatorTable>
              </b-collapse>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <Loading :data="null"></Loading>
    </template>
    <b-modal id="p-port-map-modal" :title="$t('views.nd.bcd.connection_path')" size="xl">
      <b-row>
        <b-col>
          <PPortPath v-if="map_p_port" :p_port="map_p_port" />
          <b-card class="shadow-sm network-card" no-body>
            <PPortNetwork v-if="map_p_port" :p_port="map_p_port"/>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <EVLogViewer v-if="ev_p_port_gpk" modal_id="p_port-evlog"
                 :title="$t('system.event_log') + ' ' + p_port.mdl_fq_name + ' ' + p_port.name"
                 refobj_id_field="gpk"
                 :refobj_id_value="ev_p_port_gpk" ref_obj_fq="nd.p_port"></EVLogViewer>
    <PatchRequestForm ref="prf"/>
    <PatchActionForm @ok="handleAction"/>
  </b-container>
</template>

<script>

import netdocService from '@/api-services/netdoc.service'
import PatchRequestForm from '@/components/PatchRequestForm.vue'
import PatchActionForm from '@/components/PatchActionForm.vue'
import PPortNetwork from '@/components/nd/PPortNetwork.vue'
import apiutil from '@/util/apiutil'
import BCDId from '@/components/BCDId.vue'
import NETVSIcon from '@/icons/NETVSIcon.vue'
import Loading from '@/components/Loading.vue'
import EVLogViewer from '@/components/EVLogViewer.vue'
import CheckMark from '@/components/CheckMark.vue'
import PaginatorTable from '@/components/PaginatorTable.vue'
import {EventBus} from '@/eventbus'
import ComponentLocation from '@/components/nd/ComponentLocation.vue'
import PPortPath from '@/components/nd/PPortPath.vue'

export default {
  name: 'NetdocPPort',
  components: {
    PPortPath,
    ComponentLocation,
    PaginatorTable,
    CheckMark,
    EVLogViewer,
    BCDId,
    PPortNetwork,
    PatchActionForm,
    PatchRequestForm,
    NETVSIcon,
    Loading
  },
  data() {
    return {
      module_gpk: undefined,
      p_port_gpk: undefined,
      p_port: undefined,
      test_table: [],

      assigned_bcd_vlan_list: [],

      vxlans_of_bcd: {},
      vlans_of_bcd: {},
      bcds: {},

      parent_module: undefined,
      module_type: undefined,

      remote_p_port: undefined,
      remote_module: undefined,

      ev_p_port_gpk: undefined,
      map_p_port: undefined,
    }
  },
  methods: {
    showEVLog() {
      this.ev_p_port_gpk = this.p_port_gpk
      this.$root.$nextTick(() => {
        this.$root.$emit('bv::show::modal', 'p_port-evlog')
      })
    },
    show_map_for_p_port(p_port) {
      this.map_p_port = p_port
      this.$bvModal.show('p-port-map-modal')
    },
    handleAction(payload) {
      this.$refs.prf.handleAction(payload)
    },
    fetchData() {
      this.p_port = undefined
      this.module_gpk = this.$route.params.module_gpk
      this.p_port_gpk = this.$route.params.p_port_gpk
      this.$store.commit('setNavigationRefreshHandle', {gpk: this.p_port_gpk, objType: 'nd.p_port'})
      netdocService.find_bcd_for_dd(this.$store.state, this.module_gpk, this.p_port_gpk).then(r => {
        this.p_port = r.data.p_port_list[0]
        if (this.map_p_port) {
          this.map_p_port = this.p_port
        }
        if (this.p_port) {
          const n = this.p_port.name // Don't lazy eval, this can cause a poo-storm in the next line on navigation to the same view iwth a different p_port
          EventBus.$emit('overwrite_breadcrumbs', {name: () => n})
          EventBus.$emit('overwrite_breadcrumb_name_of_url', {
            url: `/netdoc/modules/${this.module_gpk}`,
            name: this.p_port.mdl_fq_name
          })
        }
        this.test_table = r.data.vlan_list
        if (r.data.dd_module_list.length >= 1) {
          this.parent_module = r.data.dd_module_list[0]
        } else {
          this.parent_module = undefined
        }
        if (r.data.module_type_list.length >= 1) {
          this.module_type = r.data.module_type_list[0]
        } else {
          this.module_type = undefined
        }

        this.bcds = apiutil.dict_by_value_of_array(r.data.bcd_list, 'name')
        this.vxlans_of_bcd = apiutil.dict_by_value_of_array(r.data.vxlan_list, 'bcd')
        this.vlans_of_bcd = apiutil.dict_of_lists_by_value_of_array(r.data.all_vlan_list, 'bcd')
        this.vlan_egress_of_vlan = apiutil.dict_by_value_of_array(r.data.vlan_egress_list, 'vlan_gfk')
        this.assigned_bcd_vlan_list = []
        for (const vlan of r.data.vlan_list) {
          const tmp = {
            bcd_name: vlan.bcd,
            vxlan: this.vxlans_of_bcd[vlan.bcd],
            tagged: this.vlan_egress_of_vlan[vlan.gpk].is_tagged,
            vlan_id: vlan.id,
          }
          this.assigned_bcd_vlan_list.push(tmp)
        }
      })
      netdocService.find_remote_p_port_for_p_port(this.$store.state, this.module_gpk, this.p_port_gpk).then(r => {
        this.remote_p_port = r.data.p_port_list[0]
        this.remote_module = r.data.module_list[0]
        if (!this.remote_module) {
          this.remote_module = r.data.o_module_list[0]
        }
      })
    }
  },
  async created() {
    this.fetchData()
  },
  computed: {
    vlan_list_fields() {
      const result = [
        {
          label: this.$tc('system.bcd', 1),
          key: 'bcd_name',
          sortable: true
        },
        {
          label: this.$t('views.nd.bcd.tagged'),
          key: 'tagged',
          sortable: true
        }
      ]
      return result
    }
  },
  watch: {
    $route() {
      this.fetchData()
    }
  }
}
</script>

<style scoped>
.collapse-icon {
  display: inline;
  vertical-align: center;
  float: right;

  transition: transform;
  transition-duration: 250ms;
}

.not-collapsed > .collapse-icon {
  transform: rotate(-180deg);
}

</style>
